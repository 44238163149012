import './import/modules.js';

$('.hamburger').on("click", function (e) {
    // e.preventDefault();
    console.log('close menu by link');
    // headermobile.classList.toggle('opened');
    menuOpen();
});

$('.form__button').on('click', function () {
    $('.form').hide();
    $('.form__alert-text--default').css({'opacity': '1', 'display' : 'inline-block'});
});

// $('.form__button').on('click', function () {
//     // $('.form').hide();
//     $('.form__alert-text--red').css('opacity', '1');
// });


function menuOpen() {
    $('.hamburger')[0].classList.toggle('is-active');
    $('.header__mobile')[0].classList.toggle('is-active');
    $('html')[0].classList.toggle('menu-is-active');
}


// $(document).ready(function () {
//   $('#popup__form1').submit(function (e) {
//       e.preventDefault();
//       var formData = new FormData($(this)[0]);
//       console.log($(this));
//
//       $.ajax({
//           url: 'mail.php',
//           type: 'POST',
//           data: formData,
//           success: function success(data) {
//               if (data.trim() === 'OK') {
//                   console.log(data);
//                   $('#popup__form1').fadeOut(400); // $('#popup').css("display","none");
//                   $('#popup__form1').css("display", "none");
//                   $('#done1').fadeIn(400); // $('#done').css("display","block");
//                   $('#done1').css("display", "block");
//               } else {
//                   console.error(data);
//                   $('#popup__form1').fadeOut(400);
//                   $('#popup__form1').css("display", "none");
//                   $('#error1').fadeIn(400);
//                   $('#error1').css("display", "block");
//               }
//       },
//       cache: false,
//       contentType: false,
//       processData: false
//       });
//       return false;
//   });
// });
