const openMenu = document.querySelectorAll('.js-menu-btn');
const closeMenu = document.querySelectorAll('.js-close-menu');


if (openMenu.length) {
    openMenu.forEach((item) => {
        item.addEventListener('click', () => {
            document.querySelector('.nav-mobile').classList.add('active');

            setTimeout(() => {
                document.querySelector('.nav-mobile__inner').classList.add('active');
            }, 300)
        });
    });

    closeMenu.forEach((item) => {
        item.addEventListener('click', () => {
            document.querySelector('.nav-mobile').classList.remove('active');

            setTimeout(() => {
                document.querySelector('.nav-mobile__inner').classList.remove('active');
            }, 300)
        });
    });
}